import * as d3 from 'd3'

export default {
  methods: {
    

    call_LINK_G2(selection, value, data, col) {

      if(value == 0 || col.FILTER == null || col.FILTER == '') return

      selection
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', 0.5).attr('font-weight', 600)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1).attr('font-weight', 'normal')
      })
      .on('click', () => { this.link_Modal(data,col) })                    
    }, 


    link_Modal(_d,_col) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {}
      let gCount= this.Options.DATA_GROUP
      if(gCount == 1) dataSource = {KEY1: Object.keys(_d)[0]}            
      if(gCount == 2) dataSource = {KEY1: Object.keys(_d)[0], KEY2: Object.keys(_d)[1],}
      if(gCount == 3) dataSource = {KEY1: Object.keys(_d)[0], KEY2: Object.keys(_d)[1], KEY3: Object.keys(_d)[2],}
      dataSource.STATUS = _col.CODE 

      // set for filterString 
      let filterString_ = ''
      filterString_ =  this.Filters[_col.FILTER] //일치하는 값만 가지고 옴

      console.log('dataSource: ', dataSource)
      console.log('filterString_ ', filterString_)
      
      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
  }
}