import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValuess         from './set_LocalValues'

import lmx_drawForms            from './draw_Forms'
import lmx_Timeline             from './draw_Timeline'
import lmx_Skyline              from './draw_Skyline'
import lmx_Legends              from './draw_Legends'
import lmx_Options              from './draw_Options'
import lmx_Detail               from './draw_Detail'

import lmx_events               from './Events'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValuess,
    lmx_events,

    lmx_drawForms,
    lmx_Timeline,
    lmx_Skyline,
    lmx_Legends,
    lmx_Detail,
    
    lmx_Options,
    
  ],

}
