export default {
  data: () => ({

    chartData:   [],
    cutoff:      null,
    
    canvasForms: null,
    canvasChart: null,

    title: null,
    timeline: null,
    primary: null,
    secoundary: null,
    chart: null,
    legends: null,
    summary: null,
    filters: null,

  }),





  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}