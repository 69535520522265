import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas() {

      this.canvasChart = this.canvasForms
      .append('g')
      .attr('transform', `translate(${this.chart.x}, ${this.chart.y})`)

      this.draw_ChartAxis() //-----> below
      this.draw_Grid() //-----> below
      if(this.legends.visible == 'Y') this.draw_Legends() //-----> below
    },

    
    draw_ChartAxis() {
      let axes = this.canvasChart
      axes
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      axes // Primary Axis
      .append('text')
      .attr('transform', `translate(${-this.primary.padding}, ${this.chart.height/2}) rotate(-90)`)
      .attr('font-size', this.primary.tSize).attr('fill', this.primary.tColor)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(this.primary.title)
    },


    draw_Grid() {
      let tick = this.chart.height / 5
      let grids = this.canvasChart
      for(let i=0; i<6; i++) {
        grids // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', tick * i).attr('y2', tick * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        grids // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (tick * i))  
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(()=> {
          let value = (this.primary.max * (i*.2)).toFixed(0)
          if(this.primary.unit.toLowerCase() == 'k') value = (value/1000).toFixed(1)
          return value + this.primary.unit
        })
      }
    },


    draw_Legends() {

      let item = this.canvasForms
      .append('g')
      .attr('id', `LEGEND_GROUP_${this.localId}`)
      .attr('transform', `translate(${this.chart.x+1}, ${this.legends.y})`)
      
      let x_ = 0
      this.chart.styles.forEach((d,i)=> {
        item
        .append('rect')
        .attr('id', `ICON_${i}_${this.localId}`)
        .attr('x', x_)
        .attr('y', (d.type=='LINE') ? 3 : 0)
        .attr('width', 6)
        .attr('height', (d.type=='LINE') ? 1 : 6)
        .attr('fill', d.bColor).attr('stroke', d.bColor)

        item
        .append('text')
        .attr('id', `LEGEND_${i}_${this.localId}`)
        .attr('transform', `translate(${x_ + 10}, 4)`)
        .attr('font-size', this.legends.tSize).attr('fill', this.legends.tColor)
        .attr('alignment-baseline', 'middle')
        .text(d.name)

        x_ = x_ + this.getNode(`#LEGEND_${i}_${this.localId}`, 'width') + 25
      })

      let len = this.getNode(`#LEGEND_GROUP_${this.localId}`, 'width')
      if(this.legends.align == 'center') {
        d3.select(`#LEGEND_GROUP_${this.localId}`)
        .attr('transform', `translate(${this.chart.x + (this.chart.width/2) - (len/2)}, ${this.legends.y})`)
      }
      if(this.legends.align == 'right') {
        d3.select(`#LEGEND_GROUP_${this.localId}`)
        .attr('transform', `translate(${this.chart.x + this.chart.width - len - 1}, ${this.legends.y})`)
      }
    },


    

  }
}
           