export default {

  methods: {
    
    draw_Timeline() {    

      let Timeline = this.canvasChart
      .append('g')
      .attr('transform', `translate(${0}, ${this.chart.height + .5})`)
      .style('font-family', 'roboto')
      
      Timeline
      .append('line')
      .attr('x1', this.chart.width).attr('y1', 0)
      .attr('x2', this.chart.width).attr('y2', 3)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      Timeline
      .selectAll('line1')
      .data(this.timeline.xItems)
      .enter()
      .append('line')
      .attr('x1', d => d.sx).attr('y1', 0)
      .attr('x2', d => d.sx).attr('y2', 3)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.timeline.xItems.forEach((d,i)=> {

        Timeline
        .append('g')
        .attr('id', `WRAP_${i}_${this.localId}`)
        .attr('transform', `translate(${d.mx}, ${this.timeline.itemY})`)

        this.gmx_TextWrap_Normal(
          Timeline, 
          {
            text: d.name,
            width: this.timeline.band - 10,
            color: this.timeline.tColor, 
            size: this.timeline.tSize, 
            align: 'middle',
            parents: `#WRAP_${i}_${this.localId}`, 
            group: `GROUP_${i}_${this.localId}`,
          }
        )
      })

      // console.log(this.timeline.xItems)
    }, 
  }
}
