import * as d3 from 'd3'

export default {
  methods: {

    draw_barChart() {

      // Draw Bar Chart 
      this.timeline.xItems.forEach((d,i)=> {

        this.chart.styles.forEach((st,s)=> {
          
          let value = this.chartData.find(f=> f[this.chart.code.item] == d.name && f[this.chart.code.bar] == st.value)[this.chart.code.value]
          if(value == undefined || value < 0) value = 0

          let bar = this.canvasChart
          .append('g')
          .attr('id', `BAR_${i}_${s}_${this.localId}`)
          .call(this.call_LINK, st.filter, d, st)

          bar
          .append('rect')
          .attr('x', d.bxs[s])
          .attr('y', this.chart.height-this.primary.axis(value))
          .attr('width', st.bSize).attr('height', this.primary.axis(value))
          .attr('fill', st.bColor).attr('opacity', st.opacity)
          
          if(st.vSize > 0 && value > 0) {
            bar
            .append('text')
            .attr('x', d.bxs[s] + (st.bSize/2))
            .attr('y', this.chart.height-this.primary.axis(value) -2)
            .attr('font-size', st.vSize).attr('fill',  st.vColor)
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'bottom')
            .text(value)
          }
        })
      })
    },
    
  }
}