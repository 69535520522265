export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(20, 20)`)
      .style('font-family', 'roboto')

      if(this.Options.TITLE_VISIBLE == 'Y') {
        this.canvasForms
        .append('text')
        .attr('x', 0).attr('y', -10)
        .attr('font-size', this.Options.TITLE_SIZE).attr('fill', '#000')
        .attr('alignment-baseline', 'middle')
        .text(this.Options.TITLE)
      }


      this.draw_TableHeader()
      this.Controller()
    },

    header_Text(selection, x, y, d, idx) {

      if(d.WRAP > 0) {
        selection
        .append('g')
        .attr('id', `WRAP_${idx}_${this.localId}`)
        .attr('transform', `translate(${x}, ${y-d.WRAP})`)
  
        this.gmx_TextWrap_Normal(
          selection, 
          {
            text: d.NAME,
            width: 40,
            color: d.color, 
            size: d.tSize, 
            align: 'middle',
            parents: `#WRAP_${idx}_${this.localId}`, 
            group: `GROUP_${idx}_${this.localId}`,
          }
        )
      } else {
        selection
        .append('text')
        .attr('x', x).attr('y', y)
        .attr('font-size', d.tSize).attr('fill', d.color)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.NAME)
      }
    },

    header_Horizontal(selection, x1, x2, y, color) {
      selection
      .append('line')
      .attr('x1', x1).attr('y1', y).attr('x2', x2).attr('y2', y)
      .attr('stroke', color).attr('stroke-width', .5)
    },

    header_Vertical(selection, x, sY, eY, color) {
      selection
      .append('line')
      .attr('x1',x).attr('y1', sY).attr('x2', x).attr('y2', eY)
      .attr('stroke', color).attr('stroke-width', .5)
    },

    data_Text(selection, x, y, align, color, value, data, col) {
      if(this.Options.ZERO_IGNORE == 'Y' && value == 0) return
      selection
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', 10)
      .attr('fill', (col.TYPE == 'DEV') ? this.setDeltaColor(value) : color)
      .attr('text-anchor', align).attr('alignment-baseline', 'middle')
      .text(value)
      .call(this.call_LINK_G2, value, data, col)
    },

    data_Horizontal(selection, _start, _y, _color) {
      selection
      .append('line')
      .attr('x1', _start).attr('y1', _y)
      .attr('x2', this.table.width).attr('y2', _y)
      .attr('stroke', _color)
      .attr('stroke-width', .5)
    },

    data_Vertical(selection, _x, _y, _color) {
      selection
      .append('line')
      .attr('x1', _x).attr('y1', _y - this.table.lineHeight)
      .attr('x2', _x).attr('y2', _y)
      .attr('stroke', _color)
      .attr('stroke-width', .5)
    },

    progressBar(selection, _x, _y, _pro, _bColor) {
      if(_pro == null) _pro = 0

      let bar = selection
      .append('g')
      .attr('transform', `translate(${_x}, ${_y - (this.table.lineHeight/2) -4.5})`)

      bar // Background bar
      .append('rect')
      .attr('x', 1).attr('y', 0)
      .attr('width', 100).attr('height', 10).attr('fill', '#f2f2f2').attr('opacity', .5)

      bar // Actual bar
      .append('rect')
      .attr('x', 1).attr('y', 0)
      .attr('width', (_pro == null) ? 0 : _pro).attr('height', 10).attr('fill', _bColor)

      bar
      .append('text')
      .attr('x', (_pro> 70) ? _pro-2 : _pro+2).attr('y', 6)
      .attr('font-size', 10).attr('fill', '#757575')
      .attr('text-anchor', (_pro> 70) ? 'end' : 'start').attr('alignment-baseline', 'middle')
      .text(_pro+'%')
    },
  }
}
           