import Defs from '../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  data: () => ({

    formSvg: null,
    chartMounted: false,

    rawData:    [],
    Title: null,
    subTitle: null,

    timeline:   {},
    skyline:    {},
    legends:    null,
    detail:       {},
    filters: [],
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },


  methods: {

    setDefaultValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      
      let sql1 = this.Queries.SQL1[0] // Timeline
      
      let sql2 = this.Queries.SQL2[0] // Skyline
     
      let sql3 = null
      if (this.Queries.SQL2) sql3 = this.Queries.SQL3[0] //Legends
      
      let sql4 = null
      if (this.Queries.SQL4[0]) sql4 = this.Queries.SQL4[0] //Detail Style
      // if (this.Queries.SQL4) this.filters = JSON.parse(JSON.stringify(this.Queries.SQL4[1])) //Detail Filter
 


      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        baseX           : sql1.X,
        baseY           : sql1.Y,
        length          : sql1.LENGTH,
        cutoff          : sql1.CUTOFF,
        startDate       : sql1.START,
        endDate         : sql1.END,
        refColumn       : sql1.REF_DATE, // ***
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : null,
        font            : sql1.FONT,
      }
      this.timeline.styles = {
        month: {
          height    : sql1.MONTH_HEIGHT,
          line      : sql1.MONTH_LINE,
          sWidth    : sql1.MONTH_STROKE_WIDTH,
          sColor    : sql1.MONTH_STROKE_COLOR,
          tSize     : sql1.MONTH_TEXT_SIZE,
          tColor    : sql1.MONTH_TEXT_COLOR,
        },
        year: {
          height    : sql1.YEAR_HEIGHT,
          line      : sql1.YEAR_LINE,
          sWidth    : sql1.YEAR_STROKE_WIDTH,
          sColor    : sql1.YEAR_STROKE_COLOR,
          tSize     : sql1.YEAR_TEXT_SIZE,
          tColor    : sql1.YEAR_TEXT_COLOR,
       },
      }



      // SKYLINE Properties -------------------------------------------------------------------------------
      let skyName = sql2.SKY_NAME.replace(/\s/g, '').split('/')
      this.skyline = {
        titleMain     : sql2.TITLE_MAIN,
        titleSub      : sql2.TITLE_SUB,
        legend: {
          type        : sql2.LEGEND_TYPE,
        },
        skyBox: {
          font        : sql2.BOX_FONT,
          height      : sql2.BOX_HEIGHT,
          gap         : sql2.BOX_GAP,
          radius      : sql2.BOX_RADIUS,
          tSize       : sql2.BOX_TEXT_SIZE,
          tMaxLen     : sql2.BOX_MAX_LEN,
        },
        refColumn     : sql2.REF_BOX, // ***
        refStatus     : sql2.REF_STATUS, //  ***
        scale         : sql2.SKY_HOVER_SCALE,
        styles        : [],
      }
      skyName.forEach((d,i)=> {
        this.skyline.styles[i] = {
          name        : sql2.SKY_NAME.                  replace(/\s/g, '').split('/')[i],
          codeValue   : sql2.SKY_STATUS.                replace(/\s/g, '').split('/')[i],
          type        : sql2.SKY_TYPE.                  replace(/\s/g, '').split('/')[i],
          tStyle      : sql2.SKY_FONT_WEIGHT.           replace(/\s/g, '').split('/')[i],
          tColor      : sql2.SKY_FONT_COLOR.            replace(/\s/g, '').split('/')[i],
          sWidth      : Number(sql2.SKY_STROKE_WEIGHT.  replace(/\s/g, '').split('/')[i]),
          sColor      : sql2.SKY_STROKE_COLOR.          replace(/\s/g, '').split('/')[i],
          bColor      : sql2.SKY_COLOR.                 replace(/\s/g, '').split('/')[i],
          opacity     : Number(sql2.SKY_OPACITY.        replace(/\s/g, '').split('/')[i]),
        }
      })
      
      // Search -------------------------------------------------------------------------------
      this.search  = {
        x             : sql2.SEARCH_X,
        y             : sql2.SEARCH_Y,
        visible       : sql2.SEARCH_VISIBLE,
        note          : sql2.SEARCH_NOTE,
        targets       : [''],
        input         : '',
        results       : [],
      }
      



      //LEGEND STATUS Properties -------------------------------------------------------------------------------
      if(sql3) {
        let legendStatus = sql3.LEGEND_STATUS.replace(/\s/g, '').split('/')
        this.legends = {
          currentStatus : 'TOTAL',
          visible       : sql3.LEGEND_VISIBLE,
          x             : sql3.LEGEND_X,
          y             : sql3.LEGEND_Y,
          width         : sql3.LEGEND_WIDTH,
          stroke        : sql3.LEGEND_STROKE_SIZE,
          strokeColor   : sql3.LEGEND_STROKE_COLOR,
          opacity       : sql3.LEGEND_OPACITY,
          radius        : sql3.LEGEND_RADIUS,
          titleSize     : sql3.LEGEND_TITLE_SIZE,
          titleColor    : sql3.LEGEND_TITLE_COLOR,
          valueSize     : sql3.LEGEND_VALUE_SIZE,
          styles:[],
          values:[],
        }
        legendStatus.forEach((d,i)=> {
          this.legends.styles[i] = {
            text        : sql3.LEGEND_NAME.        replace(/\s/g, '').split('/')[i],
            refColumn   : sql3.LEGEND_STATUS.      replace(/\s/g, '').split('/')[i],
            fColor      : sql3.LEGEND_CHART_COLOR. replace(/\s/g, '').split('/')[i],
            tColor      : sql3.LEGEND_TEXT_COLOR.  replace(/\s/g, '').split('/')[i],
          }
          // The Value for legends
          if (i == 0) {
            this.legends.values.TOTAL = this.rawData.length
          } else {
            this.legends.values[d] = this.rawData.filter(f=> f[this.skyline.refStatus] == d).length
          }
        })
      }
    

      // Detail infomation Box -------------------------------------------------------------------------------
      if(sql4) {
        this.detail = {
          visible:            sql4.DETAIL_VISIBLE,
          onOff:              sql4.DETAIL_ONOFF,
          x:                  sql4.DETAIL_X,
          y:                  sql4.DETAIL_Y,
          width:              sql4.DETAIL_WIDTH,
          tSize:              sql4.DETAIL_TEXT_SIZE,
          lineHeight:         sql4.DETAIL_LINEHEIGHT,
          codes:              sql4.DETAIL_CODE.replace(/\s/g, '').split('/'),
          name:               sql4.DETAIL_NAME.replace(/\s/g, '').split('/'),
          type:               sql4.DETAIL_TYPE.replace(/\s/g, '').split('/'),
          color:              sql4.DETAIL_VALUE_COLOR.replace(/\s/g, '').split('/'),
          wrapSize:           null,
          item: {
            name: [],
          },

          title: {
            width:            sql4.DETAIL_TITLE_WIDTH,
            tColor:           sql4.DETAIL_TITLE_COLOR,
          }
        }
        this.detail.height = sql4.DETAIL_LINEHEIGHT * this.detail.codes.length + 20
      }

      if (this.skyline.legend.type == 'CUBE') {
        this.search.y -= (this.legends.radius * 2) + 15 
        this.detail.y -= (this.legends.radius * 2) + 15
      }

      this.set_SkylineData()
    },




    set_SkylineData() {
      // ### call the global Function for Skyline Values ###
      this.init_gmx_SkylineValues_Month(
        this.rawData, 
        {
          refColumn:    this.timeline.refColumn,
          skyBox_Width: this.skyline.skyBox.width,
          skyBox_Gap:   this.skyline.skyBox.gap,
          startDate:    this.timeline.startDate,
          endDate:      this.timeline.endDate,
        }
      )


      this.rawData.forEach(d => {
        let date__  = this.get_gmx_TimelineCDate_Month(d[this.timeline.refColumn])
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
      })
      
      // Setting for the statu styles in skyline 
      this.skyline.styles.forEach(s => {
        this.rawData.filter(f => f[this.skyline.refStatus] == s.codeValue).forEach(d => {
          d.tStyle   = s.tStyle
          d.type     = s.type
          d.tSize    = this.skyline.skyBox.tSize //
          d.tColor   = s.tColor
          d.sWidth   = s.sWidth
          d.sColor   = s.sColor
          d.bColor   = s.bColor
          d.opacity  = s.opacity
          d.radius   = this.skyline.skyBox.radius
          if (s.dataColumn != '') d.progress = 'Y'
        })
      })

      this.timeline.height = this.timeline.styles.month.height + this.timeline.styles.year.height
    },



    set_Styles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
    },
  }
}