import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.common = JSON.parse(JSON.stringify(this.Common))
      this.columns = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL2))
      // this.columns = JSON.parse(JSON.stringify(this.JQueries.JSON1))
      // this.filters = JSON.parse(JSON.stringify(this.JQueries.JSON2))
      let types = JSON.parse(JSON.stringify(this.CTypes))

      this.header = types.header
      this.cells = types.cells

      let x_ = 0
      this.columns.forEach(d => {
        d.sX = x_
        d.fX = x_ + d.width
        d.mX = x_ + d.width / 2
        if (d.align == 'start') d.x = x_ + this.cells.cellSpace
        if (d.align == 'middle') d.x = x_ + (d.width/2)
        if (d.align == 'end') d.x = x_ + d.width - this.cells.cellSpace

        if (d.type == 'BAR') d.x = x_ + this.cells.cellSpace

        if (d.group == '') {
          d.sY = this.header.margin + 2
          d.fY = this.header.depth[0].height + this.header.depth[1].height - (this.header.margin + 2)
        } else {
          d.sY = this.header.depth[0].height + 2
          d.fY = this.header.depth[0].height + this.header.depth[1].height - (this.header.margin + 2)
        }

        x_ = x_ + d.width

        this.tableWidth = x_
      })

      let groups = [...new Set(this.columns.map(m => m.group))]
      groups.filter(f => f != '').forEach((d,i) => {
        let group_ = this.columns.filter(f => f.group == d)
        let sX_ = group_[0].sX
        let width_ = group_.reduce((sum, obj) => sum + obj.width, 0)

        this.titleGroup[i] = {
          title: d,
          sX: sX_,
          fX: sX_ + width_,
          width: width_,
          x: sX_ + width_ / 2,
          y: this.header.depth[0].height / 2,
          sY: (this.header.margin + 2),
          fY: this.header.depth[0].height - 2,
        }

        this.columns[this.columns.findIndex(f => f.fX == sX_ + width_)].sY = this.header.margin + 2
      })
    },
  }
}