export default {

  methods: {
    
    draw_Timeline() {    

      let Timeline = this.canvasChart
      .append('g')
      .attr('transform', `translate(${0}, ${0.5})`)
      .style('font-family', 'roboto')
      
      Timeline
      .selectAll('line1')
      .data(this.timeline.xItems)
      .enter()
      .append('line')
      .attr('x1', -3).attr('y1', d => d.sx)
      .attr('x2', 0).attr('y2', d => d.sx)
      .attr('stroke', '#757575').attr('stroke-width', .5)


      this.timeline.xItems.forEach((d,i)=> {

        Timeline
        .append('g')
        .attr('id', `WRAP_${i}_${this.localId}`)
        .attr('transform', `translate(${-5}, ${d.mx - this.timeline.itemY})`)

        this.gmx_TextWrap_Normal(
          Timeline, 
          {
            text: d.name,
            width: this.timeline.wrapWidth,
            color: this.timeline.tColor, 
            size: this.timeline.tSize, 
            align: this.timeline.align,
            parents: `#WRAP_${i}_${this.localId}`,
            group: `GROUP_${i}_${this.localId}`,
          }
        )
      })
    }, 
  }
}
