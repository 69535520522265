export default {

  methods: {

   
    Controller() {
      
      let tbl = this.table
      let cols = this.Columns

      this.Columns.forEach((col, c)=> {
        if(c < this.Options.DATA_GROUP) {
          // 소문자 칼럼을 만들고 데이터 복사해두기
          this.rawData.forEach(d=> {d[col.CODE.toLowerCase()] = d[col.CODE]})
          // 그룹칼럼 배열 만들기
          tbl.groupCodes.push(col.CODE)
        }
      })
      // console.log(tbl.groupCodes, tbl.groupCodes.length) // >>>>>>>>>>>>

      this.rawData.forEach((d,i)=> {
        d.gList = []
        
        // Overall값을 마지막 칼럼에만 할당하고 이전은 삭제
        tbl.groupCodes.forEach((code, c)=> {
          d.gList.push(d[code])
          if(c < this.Options.DATA_GROUP-1 && d[code] == 'OVERALL') d[code] = ''
        })

        //Overall Group의 x값 구하기
        let first =  d.gList.findIndex(f=> f == 'OVERALL')
        let last =  d.gList.findLastIndex(f=> f == 'OVERALL')
        if(first>-1) d.gX = cols[first].tx 
        if(i==this.rawData.length-1) d.gX = d.gX = cols[first].sx + (cols[last].ex - cols[first].sx)/2

      })
      
      // 그룹 텍스트 변환 
      this.rawData.forEach((d,i)=> {
        tbl.groupCodes.forEach((code, c)=> {
          if(i > 0 && c < this.Options.DATA_GROUP-1 && d[code.toLowerCase()] == this.rawData[i-1][code.toLowerCase()]) d[code] = ''
          if(i < this.rawData.length-1 &&c == this.Options.DATA_GROUP-1 && d[code] == 'OVERALL') d[code] = 'SUB-TOTAL'
          if(i == this.rawData.length-1 && d[code] == 'OVERALL') d[code] = 'GRAND TOTAL'
        })
      })
      this.draw_DataGrid()
      
    },
  }
}