import * as d3 from 'd3'

export default {

  methods: {

    set_LocalValues(){

      this.chartData = JSON.parse(JSON.stringify(this.DataItems))
      let json = JSON.parse(JSON.stringify(this.JQueries))
      
      //JSON
      this.title    = json.title
      this.timeline = json.timeline
      this.primary  = json.primary
      this.chart    = json.chart
      this.legends  = json.legends
      this.table    = json.table
      this.filter   = json.filter


      // Setting Timeline
      let unique = [ ...new Set(this.chartData.map(m => m[this.chart.code.item])) ]

      this.timeline.axis = d3.scaleBand()
      .domain(unique)
      .range([0, this.chart.width])
      this.timeline.band = this.timeline.axis.bandwidth()
      
      unique.forEach((d,i)=> {
        let mid = this.timeline.axis(d) + this.timeline.band / 2
        this.timeline.xItems.push({
          name: d,
          sx: this.timeline.axis(d),
          mx: mid,
          ex: this.timeline.axis(d) + this.timeline.band,
          total: 0,
          bxs: this.gmx_BarCoordinates(mid, [...this.chart.styles.map(m => m.bSize)], this.chart.barGap),
        })
      })


      // Calculate the Height of Canvas
      this.Canvas.CanvasWidth = this.chart.width + this.chart.x
      let get_Height = this.chart.height + this.chart.y + this.table.headHeight + 10
      if(this.table.visible == 'Y') get_Height = get_Height + (this.chart.styles.length * this.table.lineght)
      if(this.table.visible == 'Y' && this.table.total == 'Y') get_Height = get_Height + this.table.lineght
      this.Canvas.CanvasHeight = get_Height


      // Setting Primary
      if(this.primary.unit.substr(this.primary.unit.length-1, 1) == '%') {
        this.primary.max = this.primary.unit.substr(0,this.primary.unit.length-1)
        this.primary.unit = '%'
      } else {
        this.primary.max = (this.primary.max == 0) ? 25 : this.getMaxBound_v2(d3.max(this.chartData, c => c[this.chart.code.value]))
      }

      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])
      
    },


  }
}