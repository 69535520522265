import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import Data from '../../../../../includes/primitives/Color_Data'

export default {
  methods: {

    draw_Elements() {
      let s_ = this.config
      let tube = this.tube
      let columns = this.columns

      let TubeChart = this.svg
      .append('g')
      .attr('transform', `translate(${20}, ${20})`)
      .style('font-family', 'roboto')

      this.set_ColorDefs(TubeChart)

      let TubeScale = d3.scaleLinear()
      .domain([0, 100])
      .rangeRound([tube.height, 0])

      this.tube.scale = TubeScale

      Defs.setDefs_Reflections(TubeChart, this.localId)
      Defs.setDefs_Gradients(TubeChart, 0, 'LinearA2', tube.color, [0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(TubeChart, 1, 'LinearA9', tube.color, [0, 0.5, 1, 0.5], this.localId)

      // --------------------------------------------------------------------------------------------------------------------------------------
      // Draw Tube Chart
      // Start from here
      let chartWrapper = TubeChart
      .append('g')
      .attr('transform', `translate(${tube.x + (tube.tickness/2)},${tube.y + (tube.tickness/4)})`)

      // ---------------------------  Reflected Effect
      chartWrapper // Reflected Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height + tube.base.height})`)
      .attr('width', tube.tickness).attr('height', tube.base.height).attr('opacity', '0.75').attr('fill', '#BDBCBC')

      chartWrapper // Transparent Gradation Area
      .append('rect')
      .attr('transform', `translate(${-tube.tickness / 2},${tube.height + tube.base.height})`)
      .attr('width', tube.tickness * 2.5).attr('height', tube.base.height + 2).attr('fill', `url(#${this.localId}__Color_Reflection)`)

      chartWrapper // Shadow
      .append('ellipse')
      .attr('cx', tube.tickness / 4).attr('cy', tube.height + tube.base.height)
      .attr('rx', (tube.tickness * 1.5) / 2).attr('ry', (tube.tickness * 1.5) / 3 / 2).attr('fill', `url(#${this.localId}_Shadow)`)

      // --------------------------- d.base.height Cylinder
      chartWrapper // Series Bottom Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height})`)
      .attr('width', tube.tickness).attr('height', tube.base.height).attr('fill', `url(#${this.localId}__TubeBase)`)

      chartWrapper // Series Bottom Cap Bottom
      .append('ellipse')
      .attr('cx', tube.tickness / 2) .attr('cy', tube.height + tube.base.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeBase)`)

      // --------------------------- Tube Cylinder
      chartWrapper
      .append('rect') // Tube Cylinder Box
      .attr('width', tube.tickness).attr('height', tube.height).attr('fill', `url(#${this.localId}__TubeBox)`).attr('opacity', 0.7)

      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', '#E0E0DF').attr('opacity', 0.9)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', 0)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeCap)`)

      // --------------------------- Color Cylinder
      chartWrapper
      .append('path') // Cyinder Box
      .attr('d', `
      M ${0} ${TubeScale(0)}
      V ${TubeScale(0)}
      C 0 ${((tube.tickness/3/2)*1.25) + TubeScale(0) } ${tube.tickness} ${((tube.tickness/3/2)*1.25) + TubeScale(0) } ${tube.tickness} ${TubeScale(0) } 
      V ${TubeScale(0)} 
      `)
      .attr('fill', `url(#${this.localId}_${tube.color}_1)`)
      
      .attr('opacity', .9)
      .transition()
      .duration(500)
      .attr('d', () => {
      let h_ = TubeScale(columns.values.PRO)
      let hy = TubeScale(0) 

      return `
      M ${0} ${hy}
      V ${TubeScale(columns.values.PRO)}
      C 0 ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${h_} 
      V ${TubeScale(0)} 
      `
      })
    
      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_1)`).attr('opacity', 1)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', TubeScale(0))
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_0)`).attr('opacity', 1)
      .transition().duration(500).attr('cy', TubeScale(columns.values.PRO))

      // --------------------------- Value & Grid
      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness / 2},${tube.height + tube.base.padding})`)
      .style('font-size', columns.value.tSize[3])
      .style('font-family', 'roboto').style('font-weight', 400)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', columns.value.tColor[3])
      .attr('cursor', 'default')
      .text(`${columns.values.PRO}%`)

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness + 5).attr('y1', tube.height+0.5).attr('x2', tube.tickness + 20.5).attr('y2', tube.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness + 5).attr('y1', tube.height+0.5).attr('x2', tube.tickness + 20.5).attr('y2', tube.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness + 5).attr('y1', TubeScale(columns.values.PRO)+0.5).attr('x2', tube.tickness + 20).attr('y2', TubeScale(columns.values.PRO)+0.5)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness + 5).attr('y1', 0.5).attr('x2', tube.tickness + 20.5).attr('y2', 0)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness + 15).attr('y1', 0.5).attr('x2', tube.tickness + 15.5).attr('y2', tube.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      // TOTAL
      chartWrapper
      .append('text')
      .attr('id', 'Tube_Total')
      .attr('transform', `translate(${tube.tickness/2},${TubeScale(100)-tube.tickness/3})`)
      .style('font-size', columns.value.tSize[0])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', columns.value.tColor[0])
      .attr('cursor', 'default')
      .text(`${columns.values.TOT}`)
      .call(this.call_LINK, 0)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness/2},${TubeScale(100)-tube.tickness/3 - columns.value.tSize[0]-2})`)
      .style('font-size', columns.title.tSize[0])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', columns.title.tColor[0])
      .attr('cursor', 'default')
      .text(columns.title.visible == 'Y' ? columns.title.desc[0] : '')

      // ACTUAL
      chartWrapper
      .append('text')
      .attr('id', 'Tube_Actual')
      .attr('transform', `translate(${tube.tickness + 25},${TubeScale(columns.values.PRO/2)})`)
      .style('font-size', columns.value.tSize[1])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.value.tColor[1])
      .attr('cursor', 'default')
      .text(`${columns.values.ACT}`)
      .call(this.call_LINK, 1)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${this.getNodeElValue('#Tube_Actual', 'width') + 3 + tube.tickness + 25},${TubeScale(columns.values.PRO/2)})`)
      .style('font-size', columns.title.tSize[1])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.title.tColor[1])
      .attr('cursor', 'default')
      .text(columns.title.visible == 'Y' ? columns.title.desc[1] : '')

      // REMAIN
      chartWrapper
      .append('text')
      .attr('id', 'Tube_Remain')
      .attr('transform', `translate(${tube.tickness + 25},${TubeScale(columns.values.PRO + (100-columns.values.PRO)/2)})`)
      .style('font-size', columns.value.tSize[2])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.value.tColor[2])
      .attr('cursor', 'default')
      .text(`${columns.values.REM}`)
      .call(this.call_LINK, 2)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${this.getNodeElValue('#Tube_Remain', 'width') + 3 + tube.tickness + 25},${TubeScale(columns.values.PRO + (100-columns.values.PRO)/2)})`)
      .style('font-size', columns.title.tSize[2])
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.title.tColor[2])
      .attr('cursor', 'default')
      .text(columns.title.visible == 'Y' ? columns.title.desc[2] : '')

    },
    
  }
}