export default {

  methods: {

    draw_DataGrid() {

      let tbl = this.table
      let cols = this.Columns
      let head = this.header
      
      let lastGroup = tbl.groupCodes[tbl.groupCodes.length-1]
      
      let grid = this.canvasForms
      .append('g')
      .attr('transform', `translate(0.5, ${this.header.height + .5})`)
      .attr('cursor', 'default')

      let lY = tbl.lineHeight
      let tY = tbl.lineHeight / 2 + 1

      this.rawData.forEach((d,i)=> {

        let hLineX = 0
        let _sColor = this.Small.sColor
        
        this.Columns.forEach((col,c)=> {
          let _tX = col.tx
          let _align = col.ALIGN
          let _tColor = this.Medium.tColor
          let _sColor = this.Medium.sColor

          // 그룹 칼럼의 색상과 x값 적용 및 TEXT 호출
          if(c < this.Options.DATA_GROUP) _tColor = this.Large.tColor
          if(d[col.CODE] == 'SUB-TOTAL' || d[col.CODE] == 'GRAND TOTAL') _tX = d.gX
          if(d[col.CODE] == 'GRAND TOTAL') _align = 'middle'
          if(d[lastGroup] == 'SUB-TOTAL') _tColor = this.Large.tColor
          if(d[lastGroup] == 'GRAND TOTAL') _tColor = this.xLarge.tColor

          if(col.TYPE == 'PROG') this.progressBar(grid, col.sx, lY, d[col.CODE], col.BAR)
          else this.data_Text(grid, _tX, tY, _align, _tColor, d[col.CODE], d, col)
          
          if(i < this.rawData.length-1 && c < this.Options.DATA_GROUP-1) {
            // Horizontal Line X 설정
            if(d[col.CODE.toLowerCase()] == this.rawData[i+1][col.CODE.toLowerCase()]) hLineX = col.ex

            // Vertical Line 호출
            if(d[cols[c+1].CODE.toLowerCase()] != 'OVERALL') {
              if(c==0) this.data_Vertical(grid, col.ex, lY, this.Large.sColor)
              else if(this.Options.DATA_GROUP > 2 && c==1) this.data_Vertical(grid, col.ex, lY, this.Medium.sColor)
              else this.data_Vertical(grid, col.ex, lY, _sColor)
            }
          }
        })

        // Horizontal Line 함수 호출
        if(i < this.rawData.length-2) {
          // Sub Group일 경우
          if(this.Options.DATA_GROUP > 2) {
            let subIdx = this.rawData.findLastIndex(f=> f[cols[0].CODE.toLowerCase()] == d[cols[0].CODE.toLowerCase()] && f[cols[1].CODE.toLowerCase()] == d[cols[1].CODE.toLowerCase()])
            if(subIdx == i) _sColor = this.Medium.sColor
            if(d[cols[2].CODE.toLowerCase()] == 'OVERALL' || this.rawData[i+1][cols[2].CODE.toLowerCase()] == 'OVERALL') _sColor = this.Medium.sColor
          }
          // Main Group일 경우
          if(d[cols[1].CODE.toLowerCase()] == 'OVERALL' || this.rawData[i+1][cols[1].CODE.toLowerCase()] == 'OVERALL') _sColor = this.Large.sColor
          if(this.rawData.findLastIndex(f=> f[cols[0].CODE.toLowerCase()] == d[cols[0].CODE.toLowerCase()]) == i) _sColor = this.Large.sColor

          this.data_Horizontal(grid, hLineX, lY, _sColor)
        } else {
          // GRAND TOTAL일 경우
          this.data_Horizontal(grid, 0, lY, this.xLarge.sColor)
        }

        tY = tY + tbl.lineHeight
        lY = lY + tbl.lineHeight
      })
      
    },
    
  }
}