import * as d3 from 'd3'

export default {

  methods: {

    set_LocalValues(){

      this.chartData = JSON.parse(JSON.stringify(this.DataItems))
      let json = JSON.parse(JSON.stringify(this.JQueries))

      // JQueries
      this.title    = json.title
      this.timeline = json.timeline
      this.primary  = json.primary
      this.chart    = json.chart
      this.legends  = json.legends
      this.filter   = json.filter




      // Setting Timeline ------------------------------
      let unique = [ ...new Set(this.chartData.map(m => m[this.chart.code.item])) ]

      this.timeline.axis = d3.scaleBand()
      .domain(unique)
      .range([0, this.chart.width])
      this.timeline.band = this.timeline.axis.bandwidth()
      
      unique.forEach((d,i)=> {
        
        let mid = this.timeline.axis(d) + this.timeline.band / 2
        this.timeline.xItems.push({
          name: d,
          sx: this.timeline.axis(d),
          mx: mid,
          ex: this.timeline.axis(d) + this.timeline.band,
          sum: this.chartData.filter(f=> f[this.chart.code.item] == d).map(m=> {return m[this.chart.code.value]}).reduce((a, b) => (a + b))
        })
      })

      // Calculate the Height of Canvas
      this.Canvas.CanvasWidth = this.chart.width + this.chart.x
      this.Canvas.CanvasHeight = this.chart.height + this.chart.y + 50


      // Setting Primary


      if(this.primary.unit.substr(this.primary.unit.length-1, 1) == '%') {
        this.primary.max = this.primary.unit.substr(0,this.primary.unit.length-1)
        this.primary.unit = '%'
      } else {
        this.primary.max = this.getMaxBound_v2(d3.max(this.timeline.xItems, c => c.sum))
      }

      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])
      
    },


  }
}