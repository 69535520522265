import * as d3 from 'd3'

export default {
  methods: {
    

    call_LINK(selection, d_) {
      selection
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', 0.5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', () => {
        this.link_Modal(d_)
      }) 
    }, 

    link_Modal(d_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {[d_.col]: d_.area} // DO not Remark if possible

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      if (d_.type == 'fab') {
        filterString_ = `[S_OUT] IS NOT NULL`
      } else if (d_.type == 'install') {
        filterString_ = `[INSTALL_DATE] IS NOT NULL`
      }

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },
 
    
  }
}