export default {

  methods: {

    draw_TableHeader() {

      let Columns = this.Columns
      let depth = this.header.depth
      let tbl = this.table

      let header = this.canvasForms
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      this.header_Horizontal(header, 0, tbl.width, 0, this.xLarge.sColor)
      this.header_Horizontal(header, 0, tbl.width, this.header.height, this.xLarge.sColor)

      // draw the Group Text & Horizontal Line
      this.Headers.forEach((d,i)=> {
        this.header_Text(header, d.tx, d.ty, d, i)
        this.header_Horizontal(header, d.sx, d.ex, d.y, this.Large.sColor)
      })

      // draw Column's text
      Columns.forEach((d,i)=> {
        this.header_Text(header, d.mx, d.ty, d, i)
      })


      // Vertical Lines ----------------------------------------------------------------------------------------------------
      // Group #1
      this.Headers.forEach((d,i)=> {
        
        if(d.ROW == 1 && d.ROWSPAN == 1) {
          
          this.header_Vertical(header, d.sx, 0, tbl.height, this.Large.sColor)

          // if HEADER_GROUP == 2 then child of Header Group #1
          if(this.Options.HEADER_GROUP == 2) {
            Columns.filter(f=> f.G1 == d.GROUP).forEach((col,g)=> {
              if(g > 0) this.header_Vertical(header, col.sx, depth[1], tbl.height, this.Small.sColor)
            })
          }
        }

        // Header Group #1 -------------------------------------
        if(d.ROW == 1 && d.ROWSPAN == 2) {
          Columns.filter(f=> f.G1 == d.GROUP).forEach((col,g)=> {
            // outside only
            this.header_Vertical(header, d.sx, 0, tbl.height, this.Medium.sColor)
            // inside only
            if(g > 0) this.header_Vertical(header, col.sx, depth[1] + depth[2], tbl.height, this.Small.sColor)
          })
        }

        // Header Group #2 -------------------------------------
        if(d.ROW == 2) {
          Columns.filter(f=> f.G2 == d.GROUP).forEach((col,g)=> {
            // outside only
            this.header_Vertical(header, d.sx, depth[1], tbl.height, this.Medium.sColor)
            // inside only
            if(g > 0) this.header_Vertical(header, col.sx, depth[1] + depth[2], tbl.height, this.Small.sColor)
          })
        }
      })

      // Column Group Vertical Line ----------------------------
      Columns.forEach((d,i)=> {
        if(i < this.Options.DATA_GROUP) {
          if(i == 1) this.header_Vertical(header, d.sx, 0, this.header.height, this.Large.sColor)
          if(i == 2) this.header_Vertical(header, d.sx, 0, this.header.height, this.Medium.sColor)
        } else 
        // Column Group #1
        if(this.Options.HEADER_GROUP > 1 && d.ROW == 1) this.header_Vertical(header, d.sx, 0, tbl.height, this.Large.sColor)
        if(this.Options.HEADER_GROUP == 1) this.header_Vertical(header, d.sx, 0, tbl.height, this.Medium.sColor)
        // if HEADER_GROUP == 3 then Column Group #2
        if(this.Options.HEADER_GROUP == 3 && d.ROW == 2) this.header_Vertical(header, d.sx, depth[1], tbl.height, this.Medium.sColor)
      })

    },

  }
}