import * as d3 from 'd3'

export default {

  methods: {

    set_LocalValues(){

      this.chartData = JSON.parse(JSON.stringify(this.DataItems))
      this.cutoff = JSON.parse(JSON.stringify(this.Queries.SQL1[0].CUTOFF))
      
      let json = JSON.parse(JSON.stringify(this.JQueries))

      //##### JSON #####
      this.title       = json.title
      this.timeline    = json.timeline
      this.primary     = json.primary
      this.secoundary  = json.secoundary
      this.chart       = json.chart
      this.legends     = json.legends
      this.summary     = json.summary

      // Calculate the Height of Canvas
      this.Canvas.CanvasWidth = this.chart.width + this.chart.x * 2
      this.Canvas.CanvasHeight = this.chart.height + this.chart.y + 40


      // Setting Timeline ----------------------------
      this.timeline.axis = d3.scaleBand()
      .domain(this.chartData.map(d => d.CDATE ))
      .range([0, this.chart.width])
      this.timeline.band = this.timeline.axis.bandwidth()

      if(this.timeline.type == 'week') this.gmx_BandScaleValues_Week(this.chartData) // ##### init_TimelineValue.js #####
      if(this.timeline.type == 'month') this.gmx_BandScaleValues_Month(this.chartData) // ##### init_TimelineValue.js #####

      // Set Coordinates for Bar X -------------------
      this.chartData.forEach((d,i)=> {
        d.bxs = this.gmx_BarCoordinates(d.mx, [...this.chart.styles.filter(f=> f.type=='BAR').map(m => m.size)], this.chart.barGap)
      })
      

      //
      //
      //
      //
      //


      // Setting Primary --------------------------------------------------------
      let maxArray = []
      this.chart.styles.filter(f=> f.type == 'BAR').forEach((s,i)=> {
        maxArray.push(d3.max(this.chartData, c => c[s.code]))
      })

      this.primary.max = d3.max(maxArray)
      if(this.primary.max == 0) this.secoundary.max = 25; else this.primary.max = this.getMaxBound_v2(this.primary.max)

      this.primary.max = this.getMaxBound_v2(this.primary.max)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      if(this.primary.position == 'left') this.primary.titleX = -this.primary.padding
      if(this.primary.position == 'right') this.primary.titleX = this.chart.width + this.primary.padding



      // Setting Secoundary ------------------------------------------------------
      if(this.secoundary.unit.substr(this.secoundary.unit.length-1, 1) == '%') {
        this.secoundary.max = this.secoundary.unit.substr(0,this.secoundary.unit.length-1)
        this.secoundary.unit = '%'
      } else {
        maxArray = []
        this.chart.styles.filter(f=> f.type == 'LINE').forEach((s,i)=> {
          maxArray.push(d3.max(this.chartData, c => c[s.code]))
        })
  
        this.secoundary.max = d3.max(maxArray)
        if(this.secoundary.max == 0) this.secoundary.max = 25; else this.secoundary.max = this.getMaxBound_v2(this.secoundary.max)
      }
     
      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])

      if(this.secoundary.position == 'left') this.secoundary.titleX = -this.secoundary.padding
      if(this.secoundary.position == 'right') this.secoundary.titleX = this.chart.width + this.secoundary.padding
      
    },


  }
}