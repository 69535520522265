import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import Data from '../../../../../includes/primitives/Color_Data'

export default {
  methods: {

    draw_Elements() {

      let GaugeChart = this.svg
      .append('g')
      .attr('transform', `translate(${0}, ${0})`)
      .style('font-family', 'roboto')


      this.Chart_Title(GaugeChart) // ----->
      this.Chart_Value(GaugeChart) // ----->
      this.Chart_Gauge(GaugeChart) // ----->
    },

    Chart_Title(selection) {
      if (this.title.visible !== 'Y') return
      selection
      .append('text')
      .attr('transform', `translate(${this.gauge.x},${10})`)
      .style('font-family', 'roboto')
      .attr('cursor', 'default')
      .style('font-size', this.title.tSize)
      .attr('fill', this.title.tColor)
      .attr('font-weight', this.title.tWeight)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(this.rawData.TITLE)
    },

    Chart_Value(selection) {
      if (this.columns.visible !== 'Y') return

      let columns = this.columns
      let y_ = 35
      this.columns.header.name.forEach((d,i) => {
        selection
        .append('text')
        .attr('transform', `translate(${this.gauge.x-3},${y_})`)
        .style('font-family', 'roboto').style('font-size', columns.header.tSize[i])
        .attr('fill', columns.header.tColor[i])
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .attr('cursor', 'default')
        .text(`${d}:`)

        selection
        .append('text')
        .attr('id', `Value_${i}_${this.localId}`)
        .attr('cursor', 'default')
        .attr('transform', `translate(${this.gauge.x+3},${y_})`)
        .style('font-family', 'roboto').style('font-size', columns.value.tSize[i])
        .attr('fill', columns.value.tColor[i])
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(this.rawData[this.columns.refCode[i]].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

        y_ += columns.height
      })
      
    },

    Chart_Gauge(selection) {

      let endAn = ''
      let c = this.gauge
      let v = this.columns
      let progValue = this.rawData.PROG

      Defs.setDefs_Reflections(selection, this.localId)
      Defs.setDefs_Gradients(selection, 0, 'LinearA4', c.nColor, [1, 1, -.5, -.5], this.localId)
      Defs.setDefs_Gradients(selection, 1, 'LinearA4', c.nColor, [0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(selection, 2, 'LinearA2', c.nColor, [1,1,1,1], this.localId)

      selection
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      // Gauge Gride - Background
      var arcBg = d3.arc()
      .innerRadius(c.radius)
      .outerRadius(c.radius - c.tickness)
      .startAngle(this.angle.start * Math.PI / 180)
      .endAngle(this.angle.end * Math.PI / 180)
  
      selection
      .append("path")
      .attr("d", arcBg)
      .attr('fill', c.gColor)
      .attr('opacity', c.gOpacity)
      .attr('transform', `translate(${c.x},${c.y})`)

      // Gauge Gride - white
      let st = 0
      let an = this.angle.size
      let ro = an / 64
      let Y1 = c.y - c.radius 
      let Y2 = Y1 + c.tickness
      st = this.angle.start
      an = this.angle.end

      let Gauges = selection
      .append('g')
      
      for (var n = st;n <= an;n += ro) {
        Gauges
        .append('line')
        .attr('x1', c.x).attr('y1', Y1)
        .attr('x2', c.x).attr('y2', Y2)
        .attr('stroke', 'white').attr('stroke-width', c.gWeight)
        .attr('transform', () => `rotate(${n},${c.x},${c.y})`)
      }



      // Draw Front Chart
      let arc = d3.arc()
      .outerRadius(c.radius)
      .innerRadius(c.radius - c.tickness)
      .startAngle(this.angle.start * Math.PI / 180)
      .endAngle(() => endAn || this.angle.start * Math.PI / 180)

      selection
      .append('path')
      .attr('d', arc)
      .attr('fill', c.fColor)
      .attr('opacity', c.opacity)
      .attr('transform', `translate(${c.x},${c.y})`)
      .transition()
      .duration(750)
      .attrTween('d', ()=> {
        let endAngle_ = ( ((this.angle.size * (this.rawData.PROG/100)) + this.angle.start) * Math.PI / 180)
        let interpolate = d3.interpolate(this.angle.start * Math.PI / 180, endAngle_)
        return function(t) {
          endAn = interpolate(t)
          return arc();
        }
      })
      

      // needle
      let needleLen = c.radius - c.tickness
      let needleRadius = 5
      let centerX = 0
      let centerY = 0

      let percent = 0
      let thetaRad = percent * Math.PI

      let topX = centerX - needleLen * Math.cos(thetaRad)
      let topY = centerY - needleLen * Math.sin(thetaRad)

      let leftX = centerX - needleRadius * Math.cos(thetaRad - Math.PI / 2)
      let leftY = centerY - needleRadius * Math.sin(thetaRad - Math.PI / 2)

      let rightX = centerX - needleRadius * Math.cos(thetaRad + Math.PI / 2)
      let rightY = centerY - needleRadius * Math.sin(thetaRad + Math.PI / 2)

      selection
      .append('path')
      .attr('id', `needle_${this.localId}`)
      .attr('transform', `translate(${c.x},${c.y})`)
      .attr('d', `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`)
      .attr('fill', `url(#${this.localId}_${c.nColor}_0)`)

      d3.select(`#needle_${this.localId}`)
      .transition().duration(750)
      .attr('transform', `translate(${c.x},${c.y}) rotate(${(progValue/100) * 180})`)

      selection
      .append('circle').attr('cx', c.x).attr('cy', c.y).attr('r', 5).attr('fill', `url(#${this.localId}_${c.nColor}_1)`)

      selection
      .append('circle').attr('cx', c.x).attr('cy', c.y).attr('r', 2).attr('fill', `url(#${this.localId}_${c.nColor}_2)`)

      
      // Grid - Text
      selection
      .append('text')
      .attr('transform', `translate(${c.x - c.radius - 3},${c.y})`)
      .style('font-family', 'roboto').style('font-size', 10).attr('cursor', 'default')
      .attr('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('0%')

      selection
      .append('text')
      .attr('transform', `translate(${c.x + (c.radius + 3) * Math.sin((270-45) * Math.PI/180)},${c.y + (c.radius + 3)  * Math.cos((270-45) * Math.PI/180)})`)
      .style('font-family', 'roboto').style('font-size', 10).attr('cursor', 'default')
      .attr('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('25%')

      selection
      .append('text')
      .attr('transform', `translate(${c.x},${c.y - c.radius - 7})`)
      .style('font-family', 'roboto').style('font-size', 10).attr('cursor', 'default')
      .attr('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50%')

      selection
      .append('text')
      .attr('transform', `translate(${c.x + (c.radius + 3) * Math.sin((270-135) * Math.PI/180)},${c.y + (c.radius + 3)  * Math.cos((270-135) * Math.PI/180)})`)
      .style('font-family', 'roboto').style('font-size', 10).attr('cursor', 'default')
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('75%')

      selection
      .append('text')
      .attr('transform', `translate(${c.x + c.radius + 3},${c.y})`)
      .style('font-family', 'roboto').style('font-size', 10).attr('cursor', 'default')
      .attr('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('100%')

      // Progress(%)
      selection
      .append('text')
      .attr('transform', `translate(${c.x},${c.y + 25})`)
      .style('font-family', 'roboto').style('font-size', 18).attr('cursor', 'default')
      .attr('fill', v.value.tColor[3]).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .transition().duration(750)
      .tween("text", function() {
        var i = d3.interpolateNumber(1, progValue)
        return function(t) {
        d3.select(this).text( `${Number(i(t).toFixed(1))}%` )
        }
      })
    },

    
  }
}