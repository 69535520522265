export default {
  data: () => ({

    chartData:    [],
    
    canvasForms:  null,
    canvasChart:  null,

    title:        null,
    timeline:     null,
    primary:      null,
    chart:        null,
    legends:      null,
    table:        null,
    filter:       null,
  }),







  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}