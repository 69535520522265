import * as d3 from 'd3'

export default {

  methods: {

    gmx_BarCoordinates(mx, bars, gap) {
      let barx = []

      bars.forEach((d,i)=>{
        let sumBar = d * (i-(bars.length/2))
        let sumGap = gap * (i-((bars.length-1)/2))
        barx.push(mx + sumBar + sumGap)
      })
      return barx
    },

    getNodeElValue(id, name) {
      if(!this.svg.select(id).node()) return 0
      return this.svg.select(id).node().getBoundingClientRect()[name]
    },

    
    getNode(id, name) {
      if(!this.svg.select(id).node()) return 0
      return this.svg.select(id).node().getBoundingClientRect()[name]
    },


    matrixCoordinate(selection) {
      let matrix = (selection.attr('transform') || '').match(/matrix(.*?)\)/g)
      matrix = matrix[0].match(/-?\d+\.?(\d+)?/g)
      
      if(matrix.length == 6) var coordinate = {
        x: parseInt(matrix[4]),
        y: parseInt(matrix[5])
      }; else coordinate = {
        x: parseInt(matrix[6]),
        y: parseInt(matrix[7])
      }

      return coordinate
    },
    

    parseTranslate(target) {
      // let translate = (target.attr('transform') || '').replace(/\s/g, '').match(/translate(.*?)\)/g)
      let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
      let coordinate = translate[0].match(/-?\d+\.?(\d+)?/g)
      return {
        x: parseInt(coordinate[0]),
        y: parseInt(coordinate[1]),
      }
    },
    

    handleImage(id_) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => {
          resolve()
        }
        img.onerror = reject
        img.src = d3.select(id_).attr('href')
        
      })
    },


    removeSvg(selection, _time, _func, _str) {
      try {
        selection.transition().duration(_time).style('opacity', 0)
      } catch(e) {
        // console.log(e)
      }

      setTimeout(() => {
        if (selection) {
          selection.remove()
          selection = null
        }
        _func(_str)
      }, _time) 
    },


    displayGroupID(_id, _delay, _duration, _opacity) {
      d3.select(`${_id}`)
      .style('opacity', 0)
      .style('visibility', 'visible')
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', _opacity)
    },


    hiddenGroupID(_id, _delay, _duration) {
      d3.select(`${_id}`)
      .transition()
      .delay(_delay)
      .duration(_duration)
      .style('opacity', 0)

      d3.select(`${_id}`)
      .transition()
      .delay(_delay + _duration)
      .style('visibility', 'hidden')
    },


  }
}
