export default {
  methods: {

    draw_Table() {
      if(this.table.visible != 'Y') return

      let tbl = this.table
      let cht = this.chart

      let table = this.canvasChart
      .append('g')
      .attr('transform', `translate(0, ${cht.height})`)
      .style('font-family', 'roboto')

      let ly = tbl.headHeight
      let tbl_Height = tbl.headHeight + (tbl.lineght * cht.styles.length)

      this.draw_Line(table, -tbl.groupWidth, cht.width, ly, ly, '#757575')

      cht.styles.forEach((st,s)=>{
        console.log('before_st',st)

        ly = ly + tbl.lineght
        this.timeline.xItems.forEach((d,i)=> {

          let value = this.chartData.find(f=> f[cht.code.bar] == st.value && f[cht.code.item] == d.name)[cht.code.value]
          d.total = d.total + value

          table
          .append('text')
          .attr('x', d.mx).attr('y', ly - (tbl.lineght/2) +1)
          .attr('font-size', 10).attr('fill', '#757575')
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(value)
          .call(this.call_LINK, st.filter, d , st)

          console.log('first_para',this.table.filter)
          console.log('second_para',st.filter)
   

          // Vertical Line
          this.draw_Line(table, d.sx, d.sx, (s==0) ? ly - tbl.lineght - tbl.headHeight : ly - tbl.lineght, ly, (i==0) ? '#757575' : '#bcbcbc')
        })

        // table legend
        table
        .append('rect')
        .attr('x', -tbl.groupWidth + 5).attr('y', ly - (tbl.lineght/2) - 3)
        .attr('width', 6).attr('height', 6).attr('fill', st.bColor)

        table 
        .append('text')
        .attr('x', -tbl.groupWidth + 15).attr('y', ly - (tbl.lineght/2) +1)
        .attr('font-size', 10).attr('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(st.value)

        // Horizontal Line
        this.draw_Line(table, -tbl.groupWidth, cht.width, ly, ly, (s==cht.styles.length-1) ? '#757575' : '#bcbcbc')
      })

      // First vertical Line of outside
      this.draw_Line(table, -tbl.groupWidth, -tbl.groupWidth, tbl.headHeight, (tbl.total == 'Y') ? tbl_Height + tbl.lineght : tbl_Height, '#757575')

      // Last vertical Line of outside
      this.draw_Line(table, cht.width, cht.width, 0, (tbl.total == 'Y') ? tbl_Height + tbl.lineght : tbl_Height, '#757575')

      if(this.table.total == 'Y') this.draw_TotalValues(table, ly)
      
    },


    draw_TotalValues(selection, ly) {
      ly = ly + this.table.lineght
      selection 
      .append('text')
      .attr('x', -(this.table.groupWidth/2)).attr('y', ly - (this.table.lineght/2) +1)
      .attr('font-size', 10).attr('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('TOTAL')

      this.timeline.xItems.forEach((d,i)=> {

        selection
        .append('text')
        .attr('x', d.mx).attr('y', ly - (this.table.lineght/2) +1)
        .attr('font-size', 10).attr('fill', '#000')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.total)
        .call(this.call_LINK, this.table.filter, d , 'OVERALL')

        this.draw_Line(selection, d.sx, d.sx, ly - this.table.lineght, ly, (i==0) ? '#757575' : '#bcbcbc')
      })
      // Last vertical Line of outside
      this.draw_Line(selection, -this.table.groupWidth, this.chart.width, ly, ly, '#757575')
    },


    draw_Line(selection, x1, x2, y1, y2, color) {

      selection
      .append('line')
      .attr('x1', x1).attr('x2', x2)
      .attr('y1', y1).attr('y2', y2)
      .attr('stroke', color).attr('stroke-width', .5)

    }
  }
}