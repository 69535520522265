import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {

    gmx_BandScaleValues_Week(_data) {
       // Pre-processing for week 
       _data.forEach((d,i)=> {
        d.yyyymm = d.CDATE.substr(0,7)
        d.year = d.CDATE.substr(0,4)
        d.mx = this.timeline.axis(d.CDATE) + this.timeline.axis.bandwidth()/2

        this.timeline.week.push({
          name: d.CDATE.substr(8,2),
          sx: this.timeline.axis(d.CDATE),
          mx: d.mx,
          ex: this.timeline.axis(d.CDATE) + this.timeline.band,
        })
      })

      // Pre-processing for month 
      let unique_Month = [ ...new Set(_data.map(m => m.yyyymm)) ]

      unique_Month.forEach((d,i)=> {
        let start = this.timeline.axis(_data.find(f=> f.yyyymm == d).CDATE)
        let end = this.timeline.axis(_data.findLast(f=> f.yyyymm == d).CDATE) + this.timeline.band
        this.timeline.month.push({
          name: new Date(`${unique_Month[i]}`).toLocaleDateString("en-US", {month: "short"}),
          sx: start,
          mx: start + (end - start) / 2,
          ex: end,
        })
      })

      // Pre-processing for year 
      let unique_Year = [ ...new Set(_data.map(m => m.year)) ]

      unique_Year.forEach((d,i)=> {
        let start = this.timeline.axis(_data.find(f=> f.year == d).CDATE)
        let end = this.timeline.axis(_data.findLast(f=> f.year == d).CDATE) + this.timeline.band
        this.timeline.year.push({
          name: unique_Year[i],
          sx: start,
          mx: start + (end - start) / 2,
          ex: end,
        })
      })
    },


    gmx_BandScaleValues_Month(_data) {

    // Pre-processing for month 
    _data.forEach((d,i)=> {

      d.yyyymm = d.CDATE.substr(0,7)
      d.year = d.CDATE.substr(0,4)
      d.mx = this.timeline.axis(d.CDATE) + this.timeline.axis.bandwidth()/2

      this.timeline.month.push({
        name: new Date(`${d.CDATE}`).toLocaleDateString("en-US", {month: "short"}),
        sx: this.timeline.axis(d.CDATE),
        mx: d.mx,
        ex: this.timeline.axis(d.CDATE) + this.timeline.axis.bandwidth(),
      })
    })

    // Pre-processing for year 
    let unique_Year = [ ...new Set(_data.map(m => m.year)) ]

    unique_Year.forEach((d,i)=> {
      let start = this.timeline.axis(_data.find(f=> f.year == d).CDATE)
      let end = this.timeline.axis(_data.findLast(f=> f.year == d).CDATE) + this.timeline.band
      this.timeline.year.push({
        name: unique_Year[i],
        sx: start,
        mx: start + (end - start) / 2,
        ex: end,
      })
    })

   },



    init_gmx_TimelineValues(d_, type_) {
      // Timeline의 시작 날짜와 종료날짜의 Cutoff 요일의 날짜를 구한다.

      let sDate_ = moment(d_.startDate).toDate()
      let eDate_ = moment(moment(d_.endDate).format('YYYY-MM-DD 23:59:59')).toDate()

      let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
      let cutoff_Week = weekNames.findIndex(n_ => n_ == d_.weekEnd)

      let diff_ = cutoff_Week - sDate_.getDay()
      sDate_ = moment(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      diff_ = cutoff_Week - eDate_.getDay()
      eDate_ = moment(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()


      // ### Set Timeline-Values for the Day ###
      if(type_ == 'day') {
        let nextDay = moment(moment(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()

        while(nextDay <= eDate_) {
          let value_ = {
            cDate: moment(nextDay).format('YYYY-MM-DD'),
            name: moment(nextDay).format('D')
          }
          this.timeline.day.push({ value: 0, ...value_ })
          nextDay = moment(nextDay).add(1, 'days').toDate()
        }
      }



      // ### Set Timeline-Values for the Week ###
      let nextDate = moment(moment(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          sDate: moment(moment(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.week.push({ name: value_.eDate.getDate(), ...value_ })
        nextDate = moment(nextDate).add(7, 'days').toDate()
      }
      

      // ### Set Timeline-Values for the Month ###
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })


      // ### Set Timeline-Values for the Year ###
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
   
      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])
    },

    
    get_gmx_TimelineCDate_Week(date) {
      let d_ = new Date(date)
      let cdate = this.timeline.week.find(f => d_ >= f.sDate && d_ <= f.eDate)
      // return cdate ? moment(moment(cdate.eDate).format('YYYY-MM-DD')).toDate() : null
      return cdate ? cdate : null
    },


    get_gmx_MatchTimeline(wk_, gap_) {

      // Skyline의 x축의 길이와 Chart의 x축의 길이를 일치시키기
      function get_WeekendDate(date_, wk_) {
        let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
        let cutoff_Week = weekNames.findIndex(n_ => n_ == wk_)
        let cDate = moment(date_).toDate()
        let diff = cutoff_Week - cDate.getDay()
        cDate = moment(cDate).add(diff < 0 ? 7 + diff : diff, 'days').toDate()
        return cDate
      }
      
      let startDate = moment(get_WeekendDate(this.timeline.endDate, wk_))
      let endDate = moment(get_WeekendDate(this.timeline.startDate, wk_))

      let noWeek = startDate.diff(endDate, 'days')/7 + 1
      let weeksize = this.canvas.chart.width / noWeek - (gap_ * 2)
      return weeksize

    },




















  }

}