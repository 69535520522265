export default {
  
  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let json = JSON.parse(JSON.stringify(this.JQueries))


      this.Options = json.options
      this.Headers = json.headers
      this.Columns = json.columns
      this.Filters = json.filter


      // set Depth array from Options 
      this.header.depth = this.Options.HEADER_HEIGHT.split('-').map(Number)
      for(let i=0;  i<this.Options.HEADER_GROUP+1; i++) {
        this.header.height = this.header.height + this.header.depth[i]
      }

      // set Depth array from Options 
      let tSize = this.Options.TEXT_SIZE.split('-').map(Number)
      let tColor = this.Options.TEXT_COLOR.split('-')
      let sColor = this.Options.STROKE_COLOR.split('-')
      
      this.xLarge = {tSize: tSize[0] , tColor: tColor[0], sColor: sColor[0]}
      this.Large  = {tSize: tSize[1] , tColor: tColor[1], sColor: sColor[1]}
      this.Medium = {tSize: tSize[2] , tColor: tColor[2], sColor: sColor[2]}
      this.Small  = {tSize: tSize[3] , tColor: tColor[3], sColor: sColor[3]}

      // Set the Columns's coordinate
      let x = 0
      this.Columns.filter(f=>f.CODE != null).forEach(d=> {
        d.sx = x
        d.mx = x + (d.WIDTH/2)
        if(d.ALIGN == 'start') d.tx = x + this.table.padding
        if(d.ALIGN == 'middle') d.tx = x + (d.WIDTH/2)
        if(d.ALIGN == 'end') d.tx = x + d.WIDTH - this.table.padding
        d.ex = x + d.WIDTH
        x = x + d.WIDTH
        this.table.width = x
      })

      // get Table Height
      this.table.lineHeight = this.Options.LINE_HEIGHT
      this.table.height = this.header.height + (this.rawData.length * this.table.lineHeight)

      // Set Columns Text
      this.Columns.forEach(d=> {

        switch(d.ROW) {
          case 1:
            if(d.ROWSPAN == 1) {
              d.ty = this.header.depth[1] / 2
              if(this.Options.HEADER_GROUP == 1) {
                d.tSize = this.Medium.tSize
                d.tColor = this.xLarge.tColor
              } else {
                d.tSize = this.Large.tSize
                d.tColor = this.Large.tColor
              }   
            }
            if(d.ROWSPAN == 2) {
              d.ty = (this.header.depth[1] + this.header.depth[2]) / 2
              d.tSize = this.xLarge.tSize
              d.tColor = this.xLarge.tColor
            }
            if(d.ROWSPAN == 3) {
              d.ty = (this.header.depth[1] + this.header.depth[2] + this.header.depth[2]) / 2
              d.tSize = this.xLarge.tSize
              d.tColor = this.xLarge.tColor
            }
            break
          case 2:
            if(d.ROWSPAN == 1) {
              d.ty = this.header.depth[1] + (this.header.depth[2]/2)
              d.tSize = this.Medium.tSize
              d.tColor = this.Medium.tColor
            }
            if(d.ROWSPAN == 2) {
              d.ty = this.header.depth[1] + (this.header.depth[2] + this.header.depth[3]) / 2
              d.tSize = this.Large.tSize
              d.tColor = this.Large.tColor
            }
            break
          case 3:
            d.ty = this.header.depth[1] + this.header.depth[2] + (this.header.depth[3]/2) + 1
            d.tSize = this.Small.tSize
            d.tColor = this.Small.tColor
            break
        }
      })

      // Set Header Group Text
      this.Headers.forEach(d=> {

        switch(d.ROW) {
          case 1:
            d.sx = this.Columns.find(f=> f.G1 == d.GROUP).sx
            d.ex = this.Columns.findLast(f=> f.G1 == d.GROUP).ex
            d.tx = d.sx + (d.ex - d.sx) / 2
 
            if(d.ROWSPAN == 1) {
              d.ty = this.header.depth[1] / 2
              d.y = this.header.depth[1]
              d.tSize = this.xLarge.tSize
              d.tColor = this.xLarge.tColor
            }
            if(d.ROWSPAN == 2) {
              d.ty = (this.header.depth[1] + this.header.depth[2]) / 2
              d.y = this.header.depth[1] + this.header.depth[2]
              d.tSize = this.xLarge.tSize
              d.tColor = this.xLarge.tColor
            }
            break

          case 2:
            d.sx = this.Columns.find(f=> f.G2 == d.GROUP).sx
            d.ex = this.Columns.findLast(f=> f.G2 == d.GROUP).ex
            d.tx = d.sx + (d.ex - d.sx) / 2
            
            if(d.ROWSPAN == 1) {
              d.ty = this.header.depth[1] + (this.header.depth[2] / 2)
              d.y =  this.header.depth[1] + this.header.depth[2]
              d.tSize = this.Medium.tSize
              d.tColor = this.Medium.tColor
            }
            if(d.ROWSPAN == 2) {
              d.ty = this.header.depth[1] + (this.header.depth[2] + this.header.depth[3]) / 2
              d.y =  this.header.depth[1] +  this.header.depth[2] + this.header.depth[3]
              d.tSize = this.Large.tSize
              d.tColor = this.Large.tColor
            }
            break
        }
      })

    },
  }
}