
export default {
  data: () => ({

    rawData:      [],
    Options:       [],
    Headers:      [],
    Columns:      [],
    
    canvasForms:  null,

    xLarge:   {},
    Large:    {},
    Medium:  {},
    Small:    {},

    header: {
      depth: [],
      height: null,
    },

    table: {
      width: null,
      height: null,
      lineHeight: null,
      padding: 5,

      groupCodes: [],
      groupX: 0,
    },
  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

}